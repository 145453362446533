/* eslint-disable no-useless-computed-key */
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {formatCustomerType, formatLongDate, formatSourceOfFunds} from "../../utils/formatter";
import ModalConsumer from "../consumer/modalConsumer";
import ActionButton from "../button/actionButton";
import UpdateCustomerDrawer from "../drawer/updateCustomerDrawer";
import { AdminRole } from "../../utils/role";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: '24px',
        backgroundColor: theme.surface.secondary,
    },
    content: {
        width: '100%',
        padding: '24px'
    },
    header: {
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 700,
        color: '#FFFFFF',
    },
    iconSection: {
        backgroundColor: 'rgb(255 255 255 / 10%)',
        width: '53px',
        height: '53px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '33px',
    },
    detailsSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    rowContainer: {
        '& > h6': {
            fontWeight: 400,
            color: '#FFFFFF',
            opacity: 0.5,
            marginBottom: '8px'
        },
        '& > p': {
            fontWeight: 400,
            color: '#FFFFFF',
            fontSize: '16px',
            lineHeight: '21px',
            marginBottom: '32px'
        },
        '&:last-child': {
            '& > p': {
                marginBottom: '12px'
            }
        }
    },
    titleSection: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '24px',
    }
}));

const CustomerDetailsCard = ({ data, refreshCustomerView }) => {
    const classes = useStyles();
    const { id: customerId, name, email, sourceOfFunds, phone: phoneNumber, dateCreated, customerType } = data;
    const formattedCustomerType = formatCustomerType(customerType?.toUpperCase());
    const formattedDate = formatLongDate(dateCreated);
    const formattedAddress = data?.address?.formattedAddress || '-'

    const rowData = [
        {
            label: 'Name',
            value: name
        },
        {
            label: 'Customer type',
            value: formattedCustomerType
        },
        {
            label: 'Phone',
            value: phoneNumber
        },
        {
            label: 'Email',
            value: email
        },
        {
            label: 'Source of funds',
            value: formatSourceOfFunds(sourceOfFunds)
        },
        {
            label: 'Address',
            value: formattedAddress
        },
        {
            label: 'Customer ID',
            value: customerId
        },
        {
            label: 'Date joined',
            value: formattedDate
        },
    ];
    return (
        <Card elevation={0} className={classes.root}>
            <CardContent className={classes.content}>
                <div className={classes.titleSection}>
                    <Typography className={classes.header} variant="h3">Customer details</Typography>
                    <AdminRole>
                        <ModalConsumer>
                            {({ showModal }) => (
                                <ActionButton
                                    variant="secondary"
                                    size="md"
                                    onClick={() => showModal(UpdateCustomerDrawer, { data, onComplete: refreshCustomerView })}
                                >
                                    {"Edit details >"}
                                </ActionButton>
                            )}
                        </ModalConsumer>
                    </AdminRole>
                </div>
                <div className={classes.detailsSection}>
                    {rowData.map(({ value, label }, index) => (
                        <div className={classes.rowContainer} key={index}>
                            <Typography variant="h6">{label}</Typography>
                            <Typography variant="body1">{value}</Typography>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default CustomerDetailsCard;
